const localDataName = "mateInvoice";
const orderStatusOptions = ["Pending", "Shipped", "Delivered"];

const today = new Date()
const newInvoice = {
  date: `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`,
  po: "",
  userType: 2,
  terms: "",
  source: "",
  customer: "",
  alternativeEmail: "",
  salesPerson: "",
  location: "",
  gradingService: "",
  gradingInvoiceNumber: "",
  gradingServiceType: "",
  paymentNotes: "",
  paymentType: "",
  notes: "",
  comment: "",
  internalComment: "",
  trackingInfo: "",
  subTotal: 0,
  quantityTotal: 0,
  currency: "$",
  type: "",
  taxValue: 0,
  taxPercent: 0,
  memoTerm: "",
  removeAllCerts: false,
  content: [
    {
      key: 1,
      itemName: "",
      itemType: "unique",
      service: "",
      status: "",
      itemQuantity: 0,
      grade: "",
      itemRate: 0,
      itemAmount: 0,
      notes: "",
      description: "",
    },
  ],
  shipping: 0,

  grandTotal: 0,
  egp: 0,
};
const createDemoData = () => {
  return newInvoice;
};

export {
  createDemoData,
  localDataName,
  newInvoice,
  orderStatusOptions,
};
